.top-navbar {
    position: fixed;
    left: 15.5em;
    width: calc(100% - 15.5em); ;
    height: 70px;
    z-index: 1;
    background-color: #232F3E !important;
}

.content-align {
    display: flex;
    justify-content: flex-end;
}

.icon-color {
    color: white !important;
}
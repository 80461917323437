.margin-layout {
  margin: 1% !important;
}

.container-box {
  position: fixed;
  left: 15.5em;
  top: 70px;
  box-sizing: border-box;
  border: 1px solid black;
  width: calc(100% - 15.5em - 2%);
  height: calc(100% - 70px - 4%);
  overflow: auto;
  background-color: rgb(243, 243, 243);
  border: 1px solid #b0b0b0 !important;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2) !important;
}.css-1e6y48t-MuiButtonBase-root-MuiButton-root
{
    width: 10px !important;
}
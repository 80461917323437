body {
    padding: 0 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    width: 10px !important;
}

.hover-img:hover {
    color: #FFD400 !important;


}

body {
    height: 100%;
}

.btn-primary {
    background-color: #79B0DB !important;
    --bs-btn-border-color: #79B0DB !important;

}

.container-fluid {
    width: 95% !important;
}

.btn {
    border-radius: 0 !important;
}

.home-hero {
    background-image: url(images/Wallpapers.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
}

:root {
    /* --body-font-size: 0.9375rem;
    --body-font-weight: 400;
    --body-line-height: 1.5;
    --h1: 4.9086075rem !important;
    --h2: 3.03375rem !important;
    --h3: 1.875rem !important; */
    --h4-color: #006B54;
    --secondary-para: #595959;
    --default-color: black;
    --wrapper-bg: #F7F9FC;

}

.bg-light {
    background-color: #F7F9FC !important;
}

.bg-primary-cad {
    background-color: #79B0DB !important;
}

.hero-wrapper {
    width: 100%;
    min-height: 250px;
    border-radius: 20px;
    background-size: cover;
    background-position: center 35%;
    /* background-image: url(/img/Hero.jpg); */
}

.hero-header {
    color: var(--h4-color);
}

.hero-para {
    color: var(--secondary-para);
}

.btn-link {
    text-decoration: none;
    color: var(--default-colo);

}

.container-wrapper {
    background-color: var(--wrapper-bg);
    border-radius: 20px;
}

.container-wrapper-table-row {
    background-color: var(--wrapper-bg);
    border-radius: 5px;
}

.container-wrapper-table-row:hover {
    background-color: #e4f4ff;
    cursor: pointer;
}

.folder.dropdown-toggle::after {
    content: "" !important;
    border: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--h4-color);
}

.bredcrum_icon {
    width: 20px;
}

/* .homelink
{
    height: 24px;
    background-image: url(/img/home_work.svg);
    background-repeat: no-repeat;
    background-position:left center;
    padding-left: 32px !important;
}

.navbar-nav {
    --bs-nav-link-padding-x: 0;
    --bs-nav-link-padding-y: 0 !important;
} */

.css-jb2ney,
.css-zx0f3 {
    padding: 0px !important;


}

.center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.background-modal {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.pull-right {
    float: right;
}

.mouse-cursor {
    cursor: pointer;
}

.loading-container {
    margin-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Optional: Set a height to fill the container */
}

.breadcrumb-font {
    font-size: 12px !important;
    color: black !important;

}

.error-message {
    color: #ff0000;
}

.folder {
    width: 20px;
}


#react-doc-viewer {
    /* height: calc(100vh - 300px) !important; */
}

.box {
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    border: 1px solid black;
    overflow-y: scroll;
}

.subject-header {
    height: 110px;
}

.subject-body {
    height: 110px;
}

.NavBG {
    /* background-color: #FFDD00; */
    background-color: rgb(104, 166, 147);
}

.card-hover {
    transition: transform 0.2s, box-shadow 0.2s;    
  }

.card-hover:hover {
    transform: scale(1.02);
    box-shadow: 0 0px 8px rgba(0, 141, 177, 0.5) !important;
    cursor: pointer;
}

.card-hover:focus {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 255, 0.2) !important;
}

.btn-color {
    background-color: #0579d1;
    color: #fff;
}

.btn-color:hover {
    background-color: #024c84;
    color: #fff;
}

.btn-color-close {
    background-color: #fff;
    color: #000;
    outline: none;
}

.btn-color-close:hover {
    background-color: #a7a7a7;
    color: #000;
    outline: none;
}

.sign-out-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.sign-in-button {
    background-color: #ffb300;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.sign-in-button:hover {
    background-color: #b38000;
}
